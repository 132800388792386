<script>
import Vue from 'vue'
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter} from "@/components/globalFunctions";

export default Vue.extend({
  name: "msgPreviewBtn",
  data: function () {
    return {
      row: {},
      msgType: 'custom_single',
      textHtml: '',
    };
  },
  beforeMount() {
    this.row = this.getValueToDisplay(this.params)

    // if (typeof this.row.msg.sending_msg_type == 'undefined' || this.row.msg.sending_msg_type == null || this.row.msg.sending_msg_type == '') {
    //   this.msgType = 'text'
    //   this.textHtml = this.row.msg.replaceAll('\n', '<br/>')
    // } else {
    // this.msgType = this.row.comment
    /*this.msgContent.push(this.row.msg.target_msg)*/
    // }
  },

  computed: {
    ...loggedInfoObject
  },

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  methods: {

    getValueToDisplay(params) {
      // console.log('----------------------------------')
      // console.log('data : ',params.data)
      // console.log('value : ', params.value)
      // console.log('formatted', params.valueFormatted)
      // console.log('----------------------------------')
      //return params.valueFormatted ? params.valueFormatted : params.value;
      return params.data
    },

    //test
    /*    showData() {
          console.log('asdlfjkasdl;fjasdl;fj', this.row)
        },*/

    //return button title
    setBtnTitle() {
      if (this.row.percent == '100.00%') {
        return '발송이 완료되어 취소할 수 없습니다.'
      } else {
        return '발송취소'
      }
    },

    //send cancel
    sendCancel() {
      console.log(this.row)

      this.$bvModal.msgBoxConfirm('발송취소 후 수정이 불가합니다.\n발송을 취소하시겠습니까?', msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/push/cancel/${this.row.push_summary_srl}`

              axios.put(url)
                  .then(() => {
                    this.params.context.componentParent.callMaclTalkHistory()
                  })
                  .catch(err => console.log(err))
            }
          })
    },
  }
})
</script>

<template>
  <div>
    <b-button variant="primary"
              size="sm"
              v-b-modal="`preview${row.push_summary_srl}`"
    >
      조회 및 발송취소
    </b-button>

    <b-modal centered
             :id="`preview${row.push_summary_srl}`"
             title="메시지 상세 보기"
             body-class="p-0 m-0"
             ok-title="닫기"
             ok-variant="secondary"
             size="custom1"
             ok-only
    >
      <div class="modal-body" style="min-height: 300px;">

        <!--<div class="row" style="height: 100%">-->

        <div style="background-color: #b2c7da !important; border-radius: 0.25rem 0.25rem 0 0; height: 100%; min-height: 300px; padding-top: 13px;">
          <div v-if="msgType == 'custom_single'" style="position: relative;">

            <div style="display: inline-block; position: absolute; left:0; top:0;">
              <img :src="require('@/assets/mcimages/asset_kakao_profile.svg')"
                   style="margin-left: 13px; width:40px;" alt="">
            </div>

            <div style="display: inline-block;padding: 0 64px 0 70px; width: 100%">
              <p style="margin-bottom: 9px; !important;">{{ this.logged_info.mart_name }}</p>

              <div style="width:280px; background-color: white; margin-right: 0;"
                   class="style-alarm-talk">
                <div style="text-align: center; position: relative; ">
                  <div v-if="row.img_path !== ''" style="position:relative; margin-top:20px; border-radius: 1px; background-size:cover; width:280px; height:280px;"
                       :style="{'background-image':`url(${row.img_path})`}"
                  />
                  <!--                  <div style="width:100%; padding-top: 2px; padding-bottom: 2px; padding-right: 8px; text-align: right; display: inline-block;"/>-->

                  <p style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 20px 3%">
                    {{ row.comment }}
                  </p>

                  <p v-if="false" style="margin:0; line-height:140%; font-size: 1em; word-break:break-all; text-align: left; padding: 0 3%;">
                    {{ row.msg.target_msg.price }}
                  </p>


                  <div v-if="false" style="margin:8px;">
                    <b-button disabled size="sm"
                              class="btn btn-soft-secondary w-100"
                              style="padding: .8em; background-color: #efefef; color: black; border-color: #dddddd">
                      자세히 보기
                    </b-button>
                  </div>
                  <div v-if="false" style="position:relative; width:100%; height: 45px; margin:0 auto; padding:0 0 0 3%;">
                    <div style="position:absolute; left:0; width:100%; margin:0 auto; padding:10px 10px 10px 3%; text-align:left">
                      크리톡
                    </div>
                    <div style="position:absolute; right:0; padding:10px 3% 0 0; text-align:left">></div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <b-button
            block
            style="width:100%; border-radius: 0 0 0.25rem 0.25rem;"
            :disabled="row.percent == '100.00%'"
            @click="sendCancel"
        >
          {{ setBtnTitle() }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.style-alarm-talk {
  display: inline-block;
  border-radius: 16px !important;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 20px;
}

.style-alarm-talk p.title {
  background: #ffeb34 !important;
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.style-alarm-talk p.title img {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 34px;
}

</style>

<style>
.modal .modal-custom1 {
  max-width: 480px;
  width: 480px;
}
</style>
